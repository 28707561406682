"use client";
import { signIn, useSession } from "next-auth/react";
import toast from "react-hot-toast";
import { useState } from "react";
import cx from "clsx";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export function NewsletterSideBySide({
  csrfToken,
  title = "Heartfelt cafe recommendations?",
  byline = "Join our coffee community!",
  guarantee = "Coffee content only. We promise!",
}) {
  const session = useSession();
  const [submitting, setSubmitting] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const email = e.currentTarget.email.value;

    try {
      if (email) {
        await signIn("email", { email });
      } else {
        toast.error("Please enter your email address.");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7">
          <h2 className="inline sm:block lg:inline xl:block">{title}</h2>{" "}
          <p className="inline text-yellow-400 sm:block lg:inline xl:block">
            {byline}
          </p>
        </div>
        <form
          className="w-full max-w-md lg:col-span-5 lg:pt-2"
          onSubmit={handleLogin}
        >
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <div className="flex gap-x-4">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Enter your email"
            />
            <button
              type="submit"
              disabled={submitting || session.status === "authenticated"}
              className={cx(
                "flex-none rounded-md bg-yellow-400 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600",
                {
                  "bg-yellow-200 cursor-not-allowed opacity-80 hover:bg-yellow-200":
                    submitting || session.status === "authenticated",
                }
              )}
            >
              {session.status === "authenticated"
                ? "Already signed up"
                : submitting
                ? "Signing up..."
                : "Sign up"}
            </button>
          </div>
          <p className="mt-4 text-sm leading-6 text-gray-900">
            {guarantee}
            {/* <a
              href="#"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              privacy&nbsp;policy
            </a>
            . */}
          </p>
        </form>
      </div>
    </div>
  );
}
