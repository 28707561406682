"use client";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Sponsor } from "@prisma/client";

function transformNameToInitials(name: string) {
  const [first, last] = name.split(" ");
  return `${first[0]}${last ? last[0] : ""}`;
}

export function Sponsors({ sponsors }: { sponsors: Sponsor[] }) {
  return (
    <div className="flex -space-x-4 overflow-hidden items-center justify-center">
      <Tooltip.Provider delayDuration={300}>
        {sponsors.map((sponsor) => (
          <span key={sponsor.id}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <span className="inline-flex h-24 w-24 items-center justify-center rounded-full bg-yellow-400 border-4 border-white">
                  <span className="text-3xl font-medium leading-none text-black -mb-2">
                    {transformNameToInitials(sponsor.name)}
                  </span>
                </span>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                  <div className="bg-yellow-50 border-2 border-yellow-400 shadow-md p-4 rounded max-w-sm">
                    <p className="font-bold">{sponsor.name}</p>
                    {sponsor.description && (
                      <p className="text-slate-500">{sponsor.description}</p>
                    )}
                    {sponsor.message && (
                      <p className="mt-3">{sponsor.message}</p>
                    )}
                  </div>
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </span>
        ))}
      </Tooltip.Provider>
    </div>
  );
}
